import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { fetchBillingDetails } from '../../api/api';
import Input from '../CustomInput';

const Step5 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [billingDetails, setBillingDetails] = useState(formData.billingAddress);
  const [billingTaxId, setBillingTaxId] = useState(formData.billingTaxId);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setBillingDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleFetchDetails = async () => {
      setLoading(true);
      const details = await fetchBillingDetails(billingTaxId);
      setBillingDetails(prev => ({ ...prev, ...details }));
      updateFormData({ billingDetails: { ...details }, billingTaxId });
      setLoading(false);
  };

  const handleNext = () => {
    if( billingDetails.legalName && billingDetails.addressLine1 && billingDetails.addressLine2 && billingDetails.city && billingDetails.state && billingDetails.postalCode && billingTaxId ) {
      updateFormData({ billingDetails });
      nextStep();
    } else {
      setErrors({
        legalName: !billingDetails.legalName ? 'Legal Name is required' : '',
        addressLine1: !billingDetails.addressLine1 ? 'Address Line 1 is required' : '',
        addressLine2: !billingDetails.addressLine2 ? 'Address Line 2 is required' : '',
        city: !billingDetails.city ? 'City is required' : '',
        state: !billingDetails.state ? 'State is required' : '',
        postalCode: !billingDetails.postalCode ? 'Zip Code is required' : '',
        billingTaxId: !billingTaxId ? 'Tax ID is required' : '',
      });
    } 
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 5: Billing Details</h2>
      <div className="mb-4">
        <div className="flex w-full">
          <Input
            type="text"
            value={billingTaxId}
            onChange={(e) => setBillingTaxId(e.target.value)}
            pattern="\d{2}\.?\d{3}\.?\d{3}/?\d{4}-?\d{2}"
            placeholder="00.000.000/0000-00"
            label={"Billing Tax ID (CNPJ)"}
            error={errors && errors.billingTaxId}
          />
          <div className='flex flex-col justify-end'>
            <button
              onClick={handleFetchDetails}
              className="ml-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              disabled={loading}
            >
            {loading ? 'Fetching...' : 'Fetch'}
          </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.legalName}
          onChange={(e) => handleChange('legalName', e.target.value)}
          label={"Company Legal Name"}
          error={errors && errors.legalName}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.addressLine1}
          onChange={(e) => handleChange('addressLine1', e.target.value)}
          label={"Address Line 1 (Logradouro)"}
          error={errors && errors.addressLine1}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.addressLine2}
          onChange={(e) => handleChange('addressLine2', e.target.value)}
          label={"Address Line 2 (Bairro)"}
          error={errors && errors.addressLine2}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.city}
          onChange={(e) => handleChange('city', e.target.value)}
          label={"City (Localidade)"}
          error={errors && errors.city}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.state}
          onChange={(e) => handleChange('state', e.target.value)}
          label={"State (Estado)"}
          error={errors && errors.state}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.postalCode}
          onChange={(e) => handleChange('postalCode', e.target.value)}
          placeholder="00000-000"
          label={"Postal Code (CEP)"}
          error={errors && errors.postalCode}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={billingDetails?.country}
          onChange={(e) => handleChange('country', e.target.value)}
          label={"Country (País)"}
          error={errors && errors.country}
        />
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step5;