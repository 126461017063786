import React, { useContext } from 'react';
import { FormContext } from '../../context/FormContext';

const Step8 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);

  const handleChange = (e) => {
    updateFormData({ paymentMethod: e.target.value });
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 8: Payment Options</h2>
      <div className="mb-4">
        <label className="block mb-2">Choose Payment Method:</label>
        <div className="flex items-center mb-2">
          <input
            type="radio"
            id="bankTransfer"
            name="paymentMethod"
            value="Bank Transfer"
            checked={formData.paymentMethod === 'Bank Transfer'}
            onChange={handleChange}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label htmlFor="bankTransfer" className="ml-2">Bank Transfer</label>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="radio"
            id="bankTicket"
            name="paymentMethod"
            value="Bank Ticket"
            checked={formData.paymentMethod === 'Bank Ticket'}
            onChange={handleChange}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
          <label htmlFor="bankTicket" className="ml-2">Bank Ticket</label>
        </div>
      </div>
      <div className="mb-4">
        {formData.paymentMethod === 'Bank Transfer' && (
          <div className="bg-blue-100 p-3 rounded">
            <p>Bank Transfer Information:</p>
            <p>Account Number: 123456789</p>
            <p>Bank: ABC Bank</p>
          </div>
        )}
        {formData.paymentMethod === 'Bank Ticket' && (
          <div className="bg-green-100 p-3 rounded">
            <p>Bank Ticket Information:</p>
            <p>Please follow the instructions sent to your email to complete the payment.</p>
          </div>
        )}
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={nextStep}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step8;