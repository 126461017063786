import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { validateCoupon } from '../../api/api';
import Input from '../CustomInput';

const Step4 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [couponCode, setCouponCode] = useState(formData.couponCode || '');
  const [errors, setErrors] = useState({});

  const handleNext = () => {

    const fetchCoupon = async () => {
      setErrors({});
      const result = await validateCoupon(couponCode);
      if( result ) {
        updateFormData({ couponCode });
        nextStep();
      } else {
        setErrors({couponCode: "Invalid Coupon Code."});
      }
    };

    if (couponCode) {
      fetchCoupon();
    }
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 4: Coupon</h2>
      <div className="mb-4">
        <label className="block mb-2"></label>
        <Input
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          label={"Coupon Code"}
          error={errors && errors.couponCode }
        />
      </div>

      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step4;