

const CustomInput = ({
    error, 
    name, 
    value, 
    id, 
    label, 
    placeholder, 
    type = "text", 
    onChange,
    icon, 
    pattern, 
}) => (
    <div className="w-full">
        <label htmlFor={ id } className="block text-sm font-medium leading-6 text-gray-900">{ label }</label>
        <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {
                    icon || 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                        <path fillRule="evenodd" d="M3.25 3A2.25 2.25 0 0 0 1 5.25v9.5A2.25 2.25 0 0 0 3.25 17h13.5A2.25 2.25 0 0 0 19 14.75v-9.5A2.25 2.25 0 0 0 16.75 3H3.25Zm.943 8.752a.75.75 0 0 1 .055-1.06L6.128 9l-1.88-1.693a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 0 1-1.06-.055ZM9.75 10.25a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z" clipRule="evenodd" />
                    </svg>
                }
            </div>

            <input 
                type={ type } 
                name={ name } 
                id={ id } 
                value={value} 
                onChange={ onChange }
                className={`${ error ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500": "" } pl-10 block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`} 
                placeholder={ placeholder } 
                aria-invalid="true" 
                aria-describedby={`${id}-error`} 
                pattern={pattern}
                autoComplete
                required
            />
            {
                error && 
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" dataslot="icon">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
                    </svg>
                </div>
            }
        </div>
        {
            error && 
            <p className="mt-2 text-sm text-red-600" id={`${id}-error`} >
                { error }
            </p>
        }
    </div>
);

export default CustomInput;