import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from '../CustomInput';

const Step1 = ({ nextStep }) => {
    const { formData, updateFormData } = useContext(FormContext);
    const [number, setNumber] = useState(formData.numberOforderLines);
    const [ errors, setErrors ] = useState({});

    const handleNext = () => {

      if( !number ) {
        setErrors({ number: "Please enter a valid number." });
        return;
      }
      
        updateFormData({ numberOforderLines: Number(number) });
        // Initialize orderLines array
        const orderLines = Array.from({ length: Number(number) }, () => ({
            taxId: '15.385.241/0001-73',
            name: 'Milênio 3',
            ShareholderCount: 4,
        }));
        updateFormData({ orderLines });
        nextStep();
    };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 1: Number of orderLines</h2>
      <div className="mb-4">
        <Input
          label={"How many orderLines do you want to add in your order?"}
          type="number"
          value={number}
          onChange={(e) => setNumber(Number(e.target.value))}
          error={errors && errors?.number}
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
              <path fillRule="evenodd" d="M3.5 2A1.5 1.5 0 0 0 2 3.5V15a3 3 0 1 0 6 0V3.5A1.5 1.5 0 0 0 6.5 2h-3Zm11.753 6.99L9.5 14.743V6.257l1.51-1.51a1.5 1.5 0 0 1 2.122 0l2.121 2.121a1.5 1.5 0 0 1 0 2.122ZM8.364 18H16.5a1.5 1.5 0 0 0 1.5-1.5v-3a1.5 1.5 0 0 0-1.5-1.5h-2.136l-6 6ZM5 16a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" clipRule="evenodd" />
            </svg>
          }
        />
      </div>
      {number >= 2 && (
        <div className="bg-gray-100 p-3 rounded mb-4">
          All the {number} orderLines will be included in the same billing account. If you need separated billing accounts, please make different orders.
        </div>
      )}
      <button
        onClick={handleNext}
        className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Next
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
      </button>
    </div>
  );
};

export default Step1;