import React, { useState } from 'react';

import Step1 from './components/steps/Step1';
import Step2 from './components/steps/Step2';
import Step3 from './components/steps/Step3';
import Step4 from './components/steps/Step4';
import Step5 from './components/steps/Step5';
import Step6 from './components/steps/Step6';
import Step7 from './components/steps/Step7';
import Step8 from './components/steps/Step8';
import Step9 from './components/steps/Step9';
import Step10 from './components/steps/Step10';
import Step11 from './components/steps/Step11';

import logo from "./assets/image/blue-icon.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MultiStepForm = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => setStep(prev => Math.min(prev + 1, 11));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 nextStep={nextStep} />;
      case 2:
        return <Step2 nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <Step3 nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <Step4 nextStep={nextStep} prevStep={prevStep} />;
      case 5:
        return <Step5 nextStep={nextStep} prevStep={prevStep} />;
      case 6:
        return <Step6 nextStep={nextStep} prevStep={prevStep} />;
      case 7:
        return <Step7 nextStep={nextStep} prevStep={prevStep} />;
      case 8:
        return <Step8 nextStep={nextStep} prevStep={prevStep} />;
      case 9:
        return <Step9 nextStep={nextStep} prevStep={prevStep} />;
      case 10:
        return <Step10 nextStep={nextStep} prevStep={prevStep} />;
      case 11:
        return <Step11 prevStep={prevStep} />;
      default:
        return <Step1 nextStep={nextStep} />;
    }
  };

  return (
    <>
      <div className='w-full flex justify-center pt-20 flex-col items-center'>
        <img src={logo} alt='' className='w-12 h-12' />
        <span className='uppercase'>subscription management order</span>
      </div>

      <div className="max-w-xl mx-2 sm:mx-auto p-6 border rounded shadow my-10">

        <nav aria-label="Progress" className='mb-8'>
          <ol role="list" className="flex items-center mx-auto max-w-max">
            {[...Array(10)].map((_, stepIdx) => (
              <li key={stepIdx} className={classNames(stepIdx !== 10 - 1 ? 'pr-2 sm:pr-4' : '', 'relative')} onClick={() => setStep(stepIdx + 1)}>
                {step > stepIdx + 1 ? (
                  <>
                    <div aria-hidden="true" className="absolute inset-0 flex items-center">
                      <div className="h-0.5 w-full bg-[#0d6efd]" />
                    </div>
                    <a
                      href="#"
                      className="relative flex h-6 w-6 sm:h-8 sm:w-8 items-center justify-center rounded-full bg-[#0d6efd] hover:bg-indigo-900"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFF" className="size-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                      </svg>

                      <span className="sr-only text-xs sm:text-sm">{stepIdx + 1}</span>
                    </a>
                  </>
                ) : step === stepIdx + 1 ? (
                  <>
                    <div aria-hidden="true" className="absolute inset-0 flex items-center">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <a
                      href="#"
                      aria-current="step"
                      className="relative flex h-6 w-6 sm:h-8 sm:w-8 items-center justify-center rounded-full border-2 border-[#0d6efd] bg-white"
                    >
                      <span aria-hidden="true" className="h-2.5 w-2.5 rounded-full bg-[#0d6efd]" />
                      <span className="sr-only text-xs sm:text-sm">{stepIdx + 1}</span>
                    </a>
                  </>
                ) : (
                  <>
                    <div aria-hidden="true" className="absolute inset-0 flex items-center">
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <a
                      href="#"
                      className="group relative flex h-6 w-6 sm:h-8 sm:w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400 [&>span]:hover:text-gray-400"
                    >
                      {/* <span
                        aria-hidden="true"
                        className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      /> */}
                      <span className="absolute text-gray-300 text-xs sm:text-sm">{stepIdx + 1}</span>
                    </a>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
        {renderStep()}
      </div>
    </>
  );
};

export default MultiStepForm;