import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from '../CustomInput';

const Step2 = ({ nextStep, prevStep }) => {
    const { formData, updateFormData } = useContext(FormContext);
    const orderLines = formData.orderLines;
    const [errors, setErrors] = useState([]);

    const handleChange = (index, field, value) => {
        const updatedorderLines = orderLines.map((orderLine, i) => 
            i === index ? { ...orderLine, [field]: value } : orderLine
        );
        updateFormData({ orderLines: updatedorderLines });
    };

    const handleNext = () => {
      var isError = false;
      orderLines.forEach((orderLine, index) => {
        let error = {};
        if (!orderLine.taxId) {
          error.taxId = 'Tax ID is required';
          isError = true;
        }
        if (!orderLine.name) {
          error.name = 'Company Name is required';
          isError = true;
        }
        if (!orderLine.ShareholderCount) {
          error.ShareholderCount = 'Shareholder Count is required';
          isError = true;
        }
        setErrors((prev) => [...prev, error]);
      })

      if(!isError) {
        nextStep();
      };
    }

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 2: Company Details</h2>
      {orderLines.map((company, index) => (
        <div key={index} className="mb-6 p-4 border rounded">
          <h3 className="text-lg font-semibold mb-2">Company {index + 1}</h3>
          <div className="mb-4">
            <Input 
              label={"Tax ID (CNPJ)"}
              value={company.taxId}
              onChange={(e) => handleChange(index, 'taxId', e.target.value)}
              placeholder={'00.000.000/0000-00'}
              pattern={"\\d{2}\\.?\\d{3}\\.?\\d{3}/?\\d{4}-?\\d{2}"}
              error={errors && errors[index]?.taxId}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                  <path fillRule="evenodd" d="M3.25 3A2.25 2.25 0 0 0 1 5.25v9.5A2.25 2.25 0 0 0 3.25 17h13.5A2.25 2.25 0 0 0 19 14.75v-9.5A2.25 2.25 0 0 0 16.75 3H3.25Zm.943 8.752a.75.75 0 0 1 .055-1.06L6.128 9l-1.88-1.693a.75.75 0 1 1 1.004-1.114l2.5 2.25a.75.75 0 0 1 0 1.114l-2.5 2.25a.75.75 0 0 1-1.06-.055ZM9.75 10.25a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5h-2.5Z" clipRule="evenodd" />
                </svg>
              }
            />
          </div>

          <div className="mb-4">
            <Input 
              label={"Company Name"}
              value={company.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              error={errors && errors[index]?.name}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                  <path fillRule="evenodd" d="M4 16.5v-13h-.25a.75.75 0 0 1 0-1.5h12.5a.75.75 0 0 1 0 1.5H16v13h.25a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1-.75-.75v-2.5a.75.75 0 0 0-.75-.75h-2.5a.75.75 0 0 0-.75.75v2.5a.75.75 0 0 1-.75.75h-3.5a.75.75 0 0 1 0-1.5H4Zm3-11a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM11 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm.5 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" clipRule="evenodd" />
                </svg>
              }
            />
          </div>

          <div className="mb-4">
            <Input 
              label={"Shareholder Count"}
              value={company.ShareholderCount}
              onChange={(e) => handleChange(index, 'ShareholderCount', e.target.value)}
              error={errors && errors[index]?.ShareholderCount}
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5">
                  <path fill-rule="evenodd" d="M3 3.5A1.5 1.5 0 0 1 4.5 2h6.879a1.5 1.5 0 0 1 1.06.44l4.122 4.12A1.5 1.5 0 0 1 17 7.622V16.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 16.5v-13Zm10.857 5.691a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 0 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                </svg>
              }
            />
          </div>

        </div>
      ))}
      <div className="flex justify-between gap-2 gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

          Back
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step2;