import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    "billingFrequency": "Monthly",
    "trialPeriod": 30,
    "paymentMethod": "CreditCard",
    "couponCode": "MICRO202402",
    "billingTaxId": "49.804.330/0001-29",
    "billingAddress": {
      "addressLine1": "Rua Manoel Goncalves Mao Cheia, 491",
      "addressLine2": "Instituto de Previdencia",
      "city": "São Paulo",
      "state": "SP",
      "postalCode": "05531-030",
      "country": "Brasil"
    },
    "administrationContact": {
      "firstName": "Filipe",
      "lastName": "Carneiro",
      "email": "filipe.carneiro@scriptumdigital.com.br",
      "mobilePhone": "+55 11 93577 2577"
    },
    "billingContact": {
      "sameAsAdmin": false, 
      "firstName": "Pedro",
      "lastName": "Gonçalves",
      "email": "pedro.goncalves@scriptumdigital.com.br",
      "businessPhone": "+55 28 4877 1753"
    },
    "saleSource": "OrganicSearch",
    "saleSourceCampaign": "Google SEO",
    "notes": "1000 ações preferenciais",
    orderLines: [
      {
        taxId: '',
        name: '',
        ShareholderCount: 0
      }
    ], 

    numberOforderLines: 1, 
  });

  const updateFormData = (newData) => {
    setFormData(prev => ({ ...prev, ...newData }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
