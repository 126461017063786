import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import { submitOrder } from '../../api/api';

const Step11 = ({ prevStep }) => {
  const { formData } = useContext(FormContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await submitOrder(formData);
    if (response) {
      setSuccess(true);
    }
    setLoading(false);
  };

  if (success) {
    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Order Submitted Successfully!</h2>
        <p>Thank you for your order.</p>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 11: Order Resume</h2>
      {/* Display a summary of formData */}
      <div className="mb-4">
        <h3 className="font-semibold">Number of orderLines:</h3>
        <p>{formData.numberOforderLines}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">orderLines:</h3>
        {formData.orderLines.map((company, index) => (
          <div key={index} className="mb-2">
            <p><strong>Company {index + 1}:</strong></p>
            <p>Tax ID: {company?.taxId}</p>
            <p>Name: {company?.name}</p>
            <p>Shareholder Count: {company?.ShareholderCount}</p>
          </div>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Billing Frequency:</h3>
        <p>{formData.billingFrequency}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Coupon:</h3>
        <p>Code: {formData?.couponCode}</p>
        {/* <p>Discount: {formData.coupon.discountPercentage}%</p> */}
        {/* <p>Trial Days: {formData.coupon.trialDays}</p> */}
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Billing Details:</h3>
        <p>Tax ID: {formData.billingAddress.taxId}</p>
        <p>Legal Name: {formData.billingAddress.legalName}</p>
        <p>Address: {formData.billingAddress.addressLine1}, {formData.billingAddress.addressLine2}, {formData.billingAddress.city}, {formData.billingAddress.state}, {formData.billingAddress.postalCode}, {formData.billingAddress.country}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Administration Contact:</h3>
        <p>Name: {formData.administrationContact.firstName} {formData.administrationContact.lastName}</p>
        <p>Email: {formData.administrationContact.email}</p>
        <p>Mobile Phone: {formData.administrationContact.mobilePhone}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Billing Contact:</h3>
        {formData.billingContact.sameAsAdmin ? (
          <p>Same as Administration Contact</p>
        ) : (
          <>
            <p>Name: {formData.billingContact.firstName} {formData.billingContact.lastName}</p>
            <p>Email: {formData.billingContact.email}</p>
            <p>Mobile Phone: {formData.billingContact.businessPhone}</p>
          </>
        )}
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Payment Method:</h3>
        <p>{formData.paymentMethod}</p>
      </div>
      <div className="mb-4">
        <h3 className="font-semibold">Sale Source:</h3>
        <p>{formData.saleSource}</p>
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full"
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default Step11;