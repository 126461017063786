import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';

const Step10 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [saleSource, setSaleSource] = useState(formData.saleSource);

  const handleChange = (e) => {
    setSaleSource(e.target.value);
  };

  const handleNext = () => {
    updateFormData({ saleSource });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 10: Sale Source (Optional)</h2>
      <div className="mb-4">
        <label className="block mb-2">Where did you find us?</label>
        <select
          value={saleSource}
          onChange={handleChange}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 outline-none"
        >
          <option value="">Select an option</option>
          <option value="OrganicSearch">Organic Search</option>
          <option value="PaidSearch">Paid Search</option>
          <option value="PartnerProtocol">Partner Protocol</option>
          <option value="PartnerContent">Partner Content</option>
          <option value="EmailMarketing">Email Marketing</option>
          <option value="OrganicSocial">Organic Social</option>
          <option value="PaidSocial">Paid Social</option>
          <option value="CustomerReferral">Customer Referral</option>
          <option value="OfflineSources">Offline Sources</option>
        </select>
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step10;