import React, { useContext, useState } from 'react';
import { FormContext } from '../../context/FormContext';
import Input from "../CustomInput";

const Step6 = ({ nextStep, prevStep }) => {
  const { formData, updateFormData } = useContext(FormContext);
  const [contact, setContact] = useState(formData.administrationContact);
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setContact(prev => ({ ...prev, [field]: value }));
  };

  const handleNext = () => {
    if(!contact.firstName || !contact.email || !contact.mobilePhone) {
      setErrors({
        firstName: !contact.firstName,
        email: !contact.email,
        mobilePhone: !contact.mobilePhone,
      });
      return;
    }

    updateFormData({ administrationContact: contact });
    nextStep();
  };

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Step 6: Administration Contact</h2>
      <div className="mb-4">
        <Input
          type="text"
          value={contact?.firstName}
          onChange={(e) => handleChange('firstName', e.target.value)}
          label={"First Name"}
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
              <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5.5-2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 12a5.99 5.99 0 0 0-4.793 2.39A6.483 6.483 0 0 0 10 16.5a6.483 6.483 0 0 0 4.793-2.11A5.99 5.99 0 0 0 10 12Z" clipRule="evenodd" />
            </svg>
          }
          error={errors && errors.firstName}
        />
      </div>
      <div className="mb-4">
        <Input
          type="text"
          value={contact?.lastName}
          onChange={(e) => handleChange('lastName', e.target.value)}
          label={"Last Name"}
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
              <path fillRule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-5.5-2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 12a5.99 5.99 0 0 0-4.793 2.39A6.483 6.483 0 0 0 10 16.5a6.483 6.483 0 0 0 4.793-2.11A5.99 5.99 0 0 0 10 12Z" clipRule="evenodd" />
            </svg>
          }
        />
      </div>
      <div className="mb-4">
        <Input
          type="email"
          value={contact?.email}
          onChange={(e) => handleChange('email', e.target.value)}
          label={"Email"}
          error={errors && errors.email}
        />
      </div>
      <div className="mb-4">
        <Input
          type="tel"
          value={contact?.mobilePhone}
          onChange={(e) => handleChange('mobilePhone', e.target.value)}
          label={"Mobile Phone (optional)"}
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
              <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 0 1 3.5 2h1.148a1.5 1.5 0 0 1 1.465 1.175l.716 3.223a1.5 1.5 0 0 1-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 0 0 6.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 0 1 1.767-1.052l3.223.716A1.5 1.5 0 0 1 18 15.352V16.5a1.5 1.5 0 0 1-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 0 1 2.43 8.326 13.019 13.019 0 0 1 2 5V3.5Z" clipRule="evenodd" />
            </svg>
          }
          error={errors && errors.mobilePhone}
        />
      </div>
      <div className="flex justify-between gap-2">
        <button
          onClick={prevStep}
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 items-center gap-2 w-full flex justify-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Back
        </button>
        <button
          onClick={handleNext}
          className="w-full flex justify-center items-center gap-x-2 rounded-md bg-[#0d6efd] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Next
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Step6;